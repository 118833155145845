/* START icon CSS */
@font-face {
  font-family: "farmx-sensor-icons";
  src: url("fonts/farmx-sensor-icons.eot?6ubnsa");
  src: url("fonts/farmx-sensor-icons.eot?6ubnsa#iefix") format("embedded-opentype"), url("fonts/farmx-sensor-icons.ttf?6ubnsa") format("truetype"), url("fonts/farmx-sensor-icons.woff?6ubnsa") format("woff"), url("fonts/farmx-sensor-icons.svg?6ubnsa#farmx-sensor-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "farmx-sensor-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-water-meter:before {
  content: "N";
}
.icon-pipe-valve:before {
  content: "I";
}
.icon-water-pump:before {
  content: "J";
}
.icon-home:before {
  content: "M";
}
.icon-radio-tower:before {
  content: "a";
}
.icon-pagelines:before {
  content: "b";
}
.icon-leaf:before {
  content: "c";
}
.icon-bolt:before {
  content: "d";
}
.icon-gauge:before {
  content: "e";
}
.icon-radio-waves:before {
  content: "f";
}
.icon-tree:before {
  content: "g";
}
.icon-tree-1:before {
  content: "h";
}
.icon-park2:before {
  content: "i";
}
.icon-thermometer:before {
  content: "j";
}
.icon-tint:before {
  content: "k";
}
.icon-ios-rainy-outline:before {
  content: "l";
}
.icon-ios-rainy:before {
  content: "m";
}
.icon-waterdrop:before {
  content: "n";
}
.icon-water:before {
  content: "o";
}
.icon-air:before {
  content: "p";
}
.icon-camera:before {
  content: "q";
}
.icon-waves:before {
  content: "r";
}
.icon-cardiac-pulse:before {
  content: "s";
}
.icon-radar-2:before {
  content: "t";
}
.icon-rss:before {
  content: "u";
}
.icon-ruby:before {
  content: "v";
}
.icon-sound:before {
  content: "w";
}
.icon-eyedropper:before {
  content: "x";
}
.icon-leaf-1:before {
  content: "y";
}
.icon-thermometer-1:before {
  content: "z";
}
.icon-cloud-sun:before {
  content: "A";
}
.icon-tachometer:before {
  content: "B";
}
.icon-ios-speedometer:before {
  content: "C";
}
.icon-speedometer:before {
  content: "D";
}
.icon-ranch:before {
  content: "K";
}
.icon-block:before {
  content: "L";
}

.user-menu {
  float: right;
  height: 50px;
  margin-top: -5px;
}
.user-menu-wrapper {
  display: flex;
  align-items: center;
}
.right-menu {
  flex-direction: row;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .right-menu {
    flex: 1 1 0px;
    display: block;
  }
  .user-menu .user-title {
    display: none;
  }
  .user-menu .anticon svg {
    height: 25px;
    width: 25px;
    margin-top: 12px;
  }
}

.nav {
  color: #ebebec;
  z-index: 3000;
}
.ant-layout-sider-light {
  border-right: 1px solid #ddd;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.sider-filler {
  flex: 1 1;
}
.menu-trigger {
  display: none;
}
.ant-layout-sider:not(.ant-layout-sider-collapsed) {
  flex: 1 1 !important;
  max-width: 320px !important;
  min-width: 320px !important;
  width: 320px !important;
}
.ant-layout-sider-trigger {
  width: 320px !important;
}
.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 50px !important;
}
.ant-list-item-action li:nth-child(2) .statusBox {
  background-color: rgba(0, 0, 0, 0.45) !important;
  margin: -7px;
}
.ant-list-item-action li:nth-child(2) div {
  width: 15px;
  min-width: 15px;
  height: 38px;
  background: aqua;
  margin: -16px;
}
@media only screen and (max-width: 600px) {
  .ant-layout-sider {
    position: absolute;
    height: calc(100% - 50px);
  }
  .ant-layout-sider:not(.ant-layout-sider-collapsed) {
    flex: 1 1 !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
  }
  .ant-layout-sider-trigger {
    width: 100% !important;
  }
  .ant-layout-sider-collapsed .ant-layout-sider-trigger {
    width: 50px !important;
  }
  .ant-layout-sider-collapsed {
    transform: translateX(-50px);
  }
  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
  .menu-trigger {
    height: 50px;
    line-height: 50px;
    display: flex;
    flex: 1 1 0px;
  }
  .menu-trigger .anticon {
    padding: 0px 15px 0px 15px;
    margin-top: 13px;
  }
}

._B5SkY {
  float: left;
}
._2Wsl8 {
  float: right;
}
._1wLl4 {
  margin-left: 53px;
}
._3P_xS {
  height: 100%;
  width: 100%;
  position: relative;
}
._djLYN {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 30px;
}
/* this is for thinner scroll-bar.
this can be removed if UI experience 
does not look good */
._fz0U6 {
  /* width */
  /* Handle */
}
._fz0U6 ::-webkit-scrollbar {
  width: 7px;
}
._fz0U6 ​ ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #504f4f;
  border-radius: 5px;
}
._fz0U6 ::-webkit-scrollbar-thumb {
  background: #504f4f;
  border-radius: 5px;
}
._rRLj7 .rc-virtual-list-holder {
  max-height: calc(100vh - 100px) !important;
}
._1Eppv {
  background-color: #647997;
}
._2fE0F {
  background-color: black;
}
._2fE0F ._2d_-o {
  color: white;
}
._2zQyk {
  background-color: black;
}
._12vYp {
  color: white !important;
}
._3t_l3 {
  color: white;
  overflow-wrap: break-word;
  width: 100%;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 8px;
  padding-bottom: 8px;
}
._1BFq2 {
  background-color: black !important;
}
._1Eppv div:first-child {
  font-size: 16px;
  line-height: 16px !important;
  color: white !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
._1Eppv div:nth-child(2) div {
  padding: 0px;
}
._1s2Io {
  display: flex;
}
._1s2Io div:first-child {
  width: 100%;
}
._1BFq2 div:first-child {
  width: 100%;
}
._1BFq2 {
  display: flex;
}
._1Eppv._2ERLJ > ._19Mfc > ._2bx_o {
  color: white !important;
}
._1s2Io div:first-child {
  color: black !important;
}
._1BFq2 li:hover,
._1s2Io li:hover {
  background-color: #c8c5c5 !important;
}
._1BFq2 li:nth-child(odd),
._1s2Io li:nth-child(odd) {
  padding-left: 45px;
}
._1BFq2 li:nth-child(even),
._1s2Io li:nth-child(even) {
  padding-left: 45px;
}
._1QkEB:nth-child(odd) {
  background-color: #ececec;
}
._1QkEB:nth-child(even) {
  background-color: #f3f3f3;
}
._1BFq2._1lNDt._2D_vV,
._1s2Io._1lNDt._2D_vV {
  padding: 0px !important;
}
._1s2Io li._znUmr {
  background-color: black !important;
  color: white !important;
}
._1BFq2 ._1rH8_ {
  margin-left: -15px;
}
._1BFq2 ._1rH8_,
._1s2Io ._1rH8_ {
  background-color: #c1c1c1 !important;
  width: 15px;
  min-width: 15px;
  height: 33px;
  margin-left: -15px;
  z-index: 11;
}
._1QkEB {
  display: flex;
  width: 100%;
}
._1QkEB ._2L6Pb {
  background-color: #c1c1c1 !important;
  width: 15px;
  min-width: 15px;
  height: 39.5px;
  margin-top: 1px;
}
._2bx_o {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
._1QkEB li ul {
  margin-left: -20px;
}
._1QkEB li span {
  width: 170px;
}
._2ALx6 {
  color: yellow;
  background-color: green;
  width: 800px;
}
._1s2Io div div span:nth-child(2) {
  overflow-wrap: break-word;
  width: 100%;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 8px;
  padding-bottom: 8px;
}
._YQbVw {
  overflow-wrap: break-word;
  width: 65px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._3rvC5 {
  color: black;
  padding: 10px;
}
._1Eppv ._3rvC5 {
  display: flex;
  align-items: center;
  justify-content: center;
}
._12vYp {
  overflow-wrap: break-word;
  width: 65px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-select-dropdown .rc-virtual-list-scrollbar {
  width: 4px !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e6f7ff !important;
}
._3MpMl {
  margin: 2px;
}
@media (min-width: 300px) and (max-width: 750px) {
  ._1QkEB li span {
    width: 168px;
  }
  ._B5SkY {
    font-size: 20px;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(50% - 10px);
    display: block;
    overflow: hidden;
  }
  ._2Wsl8 {
    font-size: 20px;
    float: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50%;
    display: block;
    overflow: hidden;
    text-align: end;
  }
  ._1QkEB li {
    width: calc(100% - 15px);
  }
  ._1BFq2 div {
    width: auto;
  }
  ._2yl_d {
    height: 40px;
    line-height: 30px;
  }
  ._2yl_d:nth-child(odd) {
    background: #e6e6e6;
  }
}
@media (min-width: 250px) and (max-width: 310px) {
  ._B5SkY {
    font-size: 20px;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50px;
    display: block;
    overflow: hidden;
  }
  ._2Wsl8 {
    font-size: 20px;
    float: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50px;
    display: block;
    overflow: hidden;
  }
  ._2yl_d {
    height: 40px;
    line-height: 30px;
  }
  ._2yl_d:nth-child(odd) {
    background: #e6e6e6;
  }
}
@media (min-width: 750px) and (max-width: 1900px) {
  ._B5SkY {
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(50% - 10px);
    display: block;
    overflow: hidden;
  }
  ._2Wsl8 {
    float: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50%;
    display: block;
    overflow: hidden;
    text-align: end;
  }
}
/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  ._fz0U6 {
    left: 0px !important;
    width: 100% !important;
    top: 0px !important;
  }
  ._1BFq2 ._1rH8_,
  ._1s2Io ._1rH8_ {
    background-color: #c1c1c1 !important;
    width: 15px;
    min-width: 15px;
    height: 33px;
  }
}

._1iCKl {
  display: flex;
}
._1JwQT {
  width: 100%;
  height: 40px;
  padding: 5px;
  z-index: 1000;
}
._3ckWs {
  display: flex;
  flex-direction: column;
}
._2iwtO {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
._2iwtO span {
  text-transform: capitalize;
}
._1t5or {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  width: 100%;
}
._3Y4n- {
  flex: 0 0 125px;
}
._2ass1 {
  margin-top: 5px;
}
._2lR1V {
  margin-top: 5px;
  overflow: hidden;
  flex-grow: 1;
}
._1m4DN {
  height: auto;
  overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
._1m4DN::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
._1m4DN {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
._2jjsw {
  max-height: 200px;
}
._2cWDT ._1Qt_F {
  display: block !important;
}
._2l_FM {
  margin: 10px;
  display: -webkit-box;
  background: lightgray;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 60px;
}
._2l_FM ._2p-7u {
  padding: 8px;
  margin-left: 5px;
  font-size: 25px;
}
._2l_FM ._2DjeH {
  width: calc(100% - 0px);
  padding-right: 0px;
  cursor: default;
  min-width: 115px;
}
._1D2_J {
  padding-right: 0px;
}
._2l_FM ._3VbiX {
  width: 100%;
}
._1MXnH {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: visible;
  margin-left: 10px;
}
._q5xEM {
  border-color: #40a9ff;
  border-width: 2px;
  font-size: 12px;
  border-radius: 6px;
  border-style: solid;
  width: 40px;
  height: auto;
  float: right;
  margin-right: 10px;
}
._1EvSf {
  width: 100%;
}
._3kT5r {
  table-layout: initial;
  width: 100%;
  height: 56px;
}
._3kT5r tbody tr td:nth-child(1) {
  width: 5%;
}
._3kT5r tbody tr td:nth-child(2) {
  width: calc(25% - 10px);
}
._3kT5r tbody tr td:nth-child(3) {
  width: 60%;
}
._3kT5r tbody tr td:nth-child(4) {
  width: 5%;
}
._3kT5r ._2Ra1z {
  width: 40px;
  margin-left: 10px;
}
._15QqT {
  width: 80px;
  margin-left: 20px;
  margin-right: 10px;
}
._1nXMc {
  width: 80px;
}
._3kT5r tbody tr td:nth-child(5) {
  width: 5%;
}
._rzXCV {
  width: 30%;
}
._PPCTf {
  width: 100px;
}
._2jtdm {
  width: 20%;
  text-align: center;
}
._JRcmk {
  width: 20%;
  text-align: end;
}
._3YX3P {
  font-weight: 600;
  width: calc(100% - 10px);
}
._1qrGd {
  font-weight: 600;
  width: calc(100% - 10px);
}
._rzXCV ._19Snh {
  color: rgba(0, 0, 0, 0.45);
  line-height: 1.5715;
  width: calc(100% - 10px);
}
._PPCTf ._3YOfW {
  color: rgba(0, 0, 0, 0.45);
  line-height: 1.5715;
  width: calc(100% - 10px);
}
._Ncgty {
  align-self: center;
}
._3mGpE {
  width: 40px;
  height: auto;
  margin-right: 10px;
}
._q5xEM {
  width: 40px;
  height: auto;
}
._19Snh,
._1qrGd,
._3YX3P,
._3YOfW {
  width: calc(100% - 10px);
  max-width: 200px;
}
@media (min-width: 320px) and (max-width: 750px) {
  ._2l_FM {
    margin: 5px;
    display: -webkit-box;
    background: lightgray;
    display: flex;
    align-items: center;
    height: 60px;
  }
  ._2l_FM ._2DjeH {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    min-width: 115px;
    display: block;
    overflow: hidden;
  }
  ._3kT5r {
    table-layout: initial;
    width: 100%;
    height: 56px;
  }
  ._3kT5r tbody tr td:nth-child(1) {
    width: 5%;
  }
  ._3kT5r tbody tr td:nth-child(2) {
    width: calc(35% - 10px);
  }
  ._3kT5r tbody tr td:nth-child(3) {
    width: 50%;
  }
  ._3kT5r tbody tr td:nth-child(4) {
    width: 5%;
  }
  ._1MXnH {
    margin-left: 10px;
    width: 100%;
  }
  ._3kT5r tbody tr td:nth-child(5) {
    width: 5%;
  }
  ._15QqT {
    width: 63px;
    margin-left: 20px;
    margin-right: 10px;
  }
  ._1nXMc {
    width: 63px;
  }
  ._2jtdm {
    display: none !important;
  }
  ._19Snh,
  ._1qrGd,
  ._3YX3P,
  ._3YOfW {
    width: calc(100% - 10px);
  }
  ._2g1Vb {
    background-color: #3c61b4;
    border-color: #3c61b4;
  }
  ._2g1Vb:focus {
    background-color: #3c61b4;
  }
}
@media (min-width: 750px) and (max-width: 1040px) {
  ._19Snh,
  ._1qrGd,
  ._3YX3P,
  ._3YOfW {
    width: calc(100% - 10px);
    max-width: 200px;
  }
  ._2l_FM {
    margin: 5px;
    display: -webkit-box;
    background: lightgray;
    display: flex;
    align-items: center;
    height: 60px;
  }
  ._2l_FM ._2DjeH {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    min-width: 115px;
    display: block;
    overflow: hidden;
  }
  ._3kT5r {
    table-layout: initial;
    width: 100%;
    height: 56px;
  }
  ._3kT5r tbody tr td:nth-child(1) {
    width: 5%;
  }
  ._3kT5r tbody tr td:nth-child(2) {
    width: calc(35% - 10px);
  }
  ._3kT5r tbody tr td:nth-child(3) {
    width: 50%;
  }
  ._3kT5r tbody tr td:nth-child(4) {
    width: 5%;
  }
  ._1MXnH {
    margin-left: 10px;
    width: 100%;
  }
  ._3kT5r tbody tr td:nth-child(5) {
    width: 5%;
  }
  ._15QqT {
    width: 63px;
    margin-left: 20px;
    margin-right: 10px;
  }
  ._1nXMc {
    width: 63px;
  }
  ._2jtdm {
    display: none !important;
  }
  ._2g1Vb {
    background-color: #3c61b4;
    border-color: #3c61b4;
  }
  ._2g1Vb:focus {
    background-color: #3c61b4;
  }
}
@media (min-width: 1440px) and (max-width: 2800px) {
  ._19Snh,
  ._1qrGd,
  ._3YX3P,
  ._3YOfW {
    width: calc(100% - 10px);
    max-width: 200px;
  }
}
@media (min-width: 1040px) and (max-width: 1440px) {
  ._19Snh,
  ._1qrGd,
  ._3YX3P,
  ._3YOfW {
    width: calc(100% - 10px);
  }
  ._2l_FM {
    margin: 5px;
    display: -webkit-box;
    background: lightgray;
    display: flex;
    align-items: center;
    height: 60px;
  }
  ._2l_FM ._2DjeH {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 0px);
    min-width: 115px;
    display: block;
    overflow: hidden;
  }
  ._3kT5r {
    table-layout: initial;
    width: 100%;
    height: 56px;
  }
  ._3kT5r tbody tr td:nth-child(1) {
    width: 5%;
  }
  ._3kT5r tbody tr td:nth-child(2) {
    width: calc(35% - 10px);
  }
  ._3kT5r tbody tr td:nth-child(3) {
    width: 50%;
  }
  ._3kT5r tbody tr td:nth-child(4) {
    width: 5%;
  }
  ._1MXnH {
    margin-left: 10px;
    width: 100%;
  }
  ._3kT5r tbody tr td:nth-child(5) {
    width: 5%;
  }
  ._15QqT {
    width: 63px;
    margin-left: 20px;
    margin-right: 10px;
  }
  ._1nXMc {
    width: 63px;
  }
}
/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  ._3awaf {
    border: 0px !important;
    background: transparent !important;
  }
  ._2jjsw {
    max-height: 150px;
    margin: -5px;
    margin-bottom: 5px;
  }
  ._2l_FM {
    margin: 5px;
    display: -webkit-box;
    background: lightgray;
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    height: 45px;
  }
  ._PPCTf {
    display: none !important;
  }
  ._2jtdm {
    display: none !important;
  }
  ._JRcmk {
    width: auto;
    text-align: end;
  }
  ._19Snh {
    width: 100px;
  }
  ._1qrGd {
    width: 100px;
  }
  ._3kT5r {
    table-layout: fixed;
    width: 100%;
    height: 56px;
  }
  ._3kT5r tbody tr td:nth-child(1) {
    width: 5%;
  }
  ._3kT5r tbody tr td:nth-child(2) {
    width: 15%;
  }
  ._1MXnH {
    margin-left: 1px;
  }
  ._3kT5r tbody tr td:nth-child(3) {
    width: 20%;
  }
  ._3kT5r tbody tr td:nth-child(4) {
    width: 10%;
  }
  ._3kT5r tbody tr td:nth-child(5) {
    width: 6%;
  }
  ._3kT5r ._2Ra1z {
    width: auto;
    margin-left: 10px;
  }
  ._3kT5r ._3mGpE {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
  ._q5xEM {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
  ._2l_FM ._2p-7u {
    font-size: 16px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  ._2l_FM ._2DjeH {
    min-width: 79px;
  }
  ._15QqT {
    width: auto;
    margin-left: 2px;
    margin-right: 3px;
  }
  ._1nXMc {
    width: 44px;
    font-size: 12px !important;
    padding: 0.2em;
  }
  ._15QqT button {
    padding: 0px;
  }
  ._1EvSf li {
    padding: 0px;
  }
  ._2g1Vb {
    background-color: #3c61b4;
    border-color: #3c61b4;
  }
  ._2g1Vb:focus {
    background-color: #3c61b4;
  }
}
/* Landscape */
/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  ._2l_FM {
    margin: 5px;
    display: -webkit-box;
    background: lightgray;
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    height: 50px;
  }
  ._2l_FM ._2DjeH {
    min-width: 90px;
  }
  ._1MXnH {
    margin-left: 0px;
  }
  ._15QqT {
    width: auto;
    margin-left: 0px;
    margin-right: 6px;
  }
  ._1nXMc {
    width: 64px;
    font-size: 12px !important;
    padding: 0.2em;
  }
  ._3mGpE {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
  ._q5xEM {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
  ._2g1Vb {
    background-color: #3c61b4;
    border-color: #3c61b4;
  }
  ._2g1Vb:focus {
    background-color: #3c61b4;
  }
}
/* Landscape */
/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  ._2l_FM ._2DjeH {
    min-width: 102px;
  }
  ._1MXnH {
    margin-left: 2px;
  }
  ._15QqT {
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
  ._1nXMc {
    width: 68px;
    font-size: 12px !important;
    padding: 0.2em;
  }
  ._2g1Vb {
    background-color: #3c61b4;
    border-color: #3c61b4;
  }
  ._2g1Vb:focus {
    background-color: #3c61b4;
  }
}
/* Landscape */
/* ----------- iPhone X ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
._24a5F {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
._24a5F ._lz-Oi {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  pointer-events: auto;
  height: calc(100vh - 15px);
}
._24a5F ._lz-Oi ._1rjCx {
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
._24a5F ._2g1Vb {
  background-color: #3c61b4;
  border-color: #3c61b4;
}
._24a5F ._2g1Vb:focus {
  background-color: #3c61b4;
}

._3Nr0O table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0 6px;
  table-layout: fixed;
}
._3Eac4 {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
._3Nr0O tr td._152Zn,
._3Nr0O tr th._152Zn {
  text-align: left;
}
._3Nr0O tr td._152Zn {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 59px;
  min-width: 59px;
  overflow: hidden;
}
._3Nr0O tr th._12Bwi,
._3Nr0O tr td._12Bwi {
  text-align: left;
}
._3Nr0O tr th._QHydv,
._3Nr0O tr td._QHydv {
  text-align: center;
}
._3Nr0O tr th._wEjrM,
._3Nr0O tr td._wEjrM {
  text-align: center;
}
._1vGGq {
  font-size: 15px;
}
._XzNZZ {
  padding: 10px 0;
}
._2Vlcc {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}
._1feDX {
  margin-right: 20px;
}
._AE10G {
  padding-left: 10px;
}
._1Ko9w {
  font-size: 30px;
  color: #d40202;
}
._3yEkF {
  width: 10px;
  height: auto;
}
tr td._2qdKu {
  cursor: pointer;
}
tr._3P7nF td:first-child {
  border-left: 10px solid #1890ff;
}
._2VVYh {
  border-left: 10px solid #d40202;
}
tr._2VVYh td:first-child {
  border-left: 5px solid #d40202;
}
tr._2-bb4 td:first-child {
  border-left: 10px solid lightgrey;
}
._3dTLR {
  border: 1px solid #d40202;
  color: #d40202;
}
tr._3dTLR td {
  border: 1px solid #d40202;
  border-left: none;
  border-right: none;
}
tr._3dTLR td:first-child {
  border: 1px solid #d40202;
  border-left: 10px solid #d40202;
  border-right: none;
}
tr._3dTLR td:last-child {
  border: 1px solid #d40202;
  border-left: none;
}
tr th:first-child {
  border-left: 10px solid #fafafa;
}
._1b9s8 tr th:first-child {
  border-left: 0px;
}
._2mKxY {
  width: 90px;
  text-align: center;
  margin-right: 0px;
  font-weight: bold;
}
._1yD4t {
  width: 120px;
  text-align: center;
  margin-right: 0px;
  font-weight: bold;
}
._3W41J {
  font-size: 30px;
  text-align: center;
  width: 64.23px;
}
._1fhRM {
  color: white !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
}
._2ZxxO {
  height: inherit;
  line-height: initial;
  text-anchor: middle;
  color: black;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
._1J-e8 {
  color: #D40202;
}
._2SLuV {
  display: flex;
  align-items: center;
}
._iVNcf {
  color: rgba(0, 0, 0, 0.65);
}
._2ntFS {
  color: rgba(0, 0, 0, 0.65);
  font-size: 24px;
  align-self: center;
  cursor: pointer;
  padding-right: 2rem;
}
._353UI {
  display: inline-flex;
}
._3R0hE {
  width: 64px;
}
._3W4g0 {
  margin-left: 10px;
  align-self: flex-end;
  width: 20px;
  height: 20px;
  align-self: end;
}
._nyLIR {
  padding: 4px 0px !important;
}
._3HFTI {
  font-size: 22px;
}
._1DZMj {
  margin-right: 7px;
}
._1DZMj ._nyLIR {
  border: none;
  padding: 0;
}
._1DZMj sup {
  top: 5px;
  right: 3px;
}
._1DZMj ._1PbxZ,
._1DZMj ._2qcyO {
  font-size: 30px;
}
._1DZMj ._1PbxZ {
  color: #333333;
}
._1DZMj ._2qcyO {
  color: #adadad;
}
tr th._QHydv {
  text-align: center;
}
._1-1Rp {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
._353UI div:nth-child(2) {
  padding-left: 4px;
}
._353UI div:nth-child(2) span:first-child {
  vertical-align: text-bottom;
}
._37UBG {
  color: lightgrey;
  font-style: italic;
}
._1gXz3 {
  color: transparent !important;
}
/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  th._12Bwi {
    display: none !important;
  }
  th._2qdKu {
    padding: 0px;
  }
  ._3Nr0O td._12Bwi {
    display: none !important;
  }
  ._3Nr0O tr td._152Zn {
    width: 40%;
  }
  tr._3P7nF td:first-child {
    border-left: 5px solid #1890ff;
  }
  tr._3dTLR td:first-child {
    border-left: 5px solid #d40202;
  }
  tr._2-bb4 td:first-child {
    border-left: 5px solid lightgrey;
  }
  tr th:first-child {
    border-left: 5px solid #fafafa;
  }
  ._1J-e8 {
    display: none !important;
  }
  ._iVNcf {
    display: none !important;
  }
  ._2ZxxO {
    padding-left: 12px;
  }
  ._2ntFS {
    font-size: 20px;
    margin-top: 5px;
  }
  ._nyLIR {
    width: 30px;
    height: 30px;
  }
  ._3HFTI {
    font-size: 18px;
  }
  ._353UI {
    margin-left: 13.5px;
    text-align: -webkit-center;
    display: flex;
    margin-right: 7px;
  }
  ._3Nr0O tr td {
    padding: 0px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 0px;
  }
  ._3Nr0O tr th {
    padding: 1px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 0px;
  }
  ._1Ko9w {
    font-size: 24px;
  }
  ._2mKxY {
    font-size: 10px;
    width: 52px;
  }
  ._3W41J {
    width: 52px;
    font-size: 24px;
  }
  ._3W4g0 {
    margin-left: 0px;
    align-self: flex-end;
    width: 10px;
    height: 20px;
    align-self: end;
  }
  tr th._3thua {
    padding-left: 12px;
  }
  ._3R0hE button span {
    font-size: 10px;
    margin-left: 22px;
  }
  .ant-switch-checked .ant-switch-inner {
    margin: 0 18px 0 2px !important;
  }
  ._AE10G ._cyrUB {
    margin-left: 10px;
  }
  ._XzNZZ {
    padding: 3px !important;
  }
  ._353UI div:nth-child(2) {
    padding-left: 0px;
  }
  ._3Nr0O tr td._1Th_M {
    max-width: 60px;
    min-width: 60px;
  }
  ._3Nr0O tr td._nVeYr {
    max-width: 62px;
    min-width: 40px;
    font-size: 10px;
  }
  ._1vGGq {
    padding-left: 4px;
  }
  ._nyLIR {
    padding: 0px !important;
  }
}
/* Landscape */
/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  ._3Nr0O tr td._2qdKu {
    width: 25%;
  }
  ._3W4g0 {
    margin-left: 0px;
    align-self: flex-end;
    width: 10px;
    height: 20px;
    align-self: end;
  }
  ._3W41J {
    width: 52px;
    font-size: 24px;
  }
  ._3R0hE button span {
    font-size: 10px;
    margin-left: 22px;
  }
}
/* Landscape */
/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  ._3Nr0O tr td._2qdKu {
    width: 25%;
  }
  ._3W4g0 {
    margin-left: 0px;
    align-self: flex-end;
    width: 10px;
    height: 20px;
    align-self: end;
  }
  ._3W41J {
    width: 52px;
    font-size: 24px;
  }
  ._3R0hE button span {
    font-size: 10px;
    margin-left: 22px;
  }
}
/* Landscape */
/* ----------- iPhone X ----------- */
/* Portrait and Landscape */
/* Portrait */
/* Landscape */
@media only screen and (max-width: 750px) {
  tr th:first-child {
    padding-left: 5px;
  }
  ._3Nr0O tr td._2qdKu {
    width: 10%;
  }
  ._3Nr0O th._12Bwi {
    display: none !important;
  }
  ._3Nr0O th._2qdKu {
    padding: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 40px;
    overflow: hidden;
  }
  ._3Nr0O td._12Bwi {
    display: none !important;
  }
  ._1J-e8 {
    display: none !important;
  }
  ._iVNcf {
    display: none !important;
  }
  ._wEjrM {
    padding-right: 5px !important;
  }
  ._2ZxxO {
    padding-left: 12px;
  }
  ._2ntFS {
    font-size: 20px;
    margin-top: 5px;
    padding-left: 0rem;
    padding-right: 0px;
  }
  ._353UI {
    margin-left: 0px;
    text-align: -webkit-center;
    display: flex;
    margin-right: 7px;
  }
  ._1Ko9w {
    font-size: 24px;
  }
  ._2mKxY {
    font-size: 10px;
    width: 52px;
  }
  ._3W41J {
    width: 52px;
    font-size: 24px;
  }
  ._3HFTI {
    vertical-align: baseline;
  }
  ._AE10G ._cyrUB {
    margin-left: 10px;
  }
}

html, body, #_1RMQI {
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
}

body {
  margin: 0;
  overflow:hidden;
  background: lightgrey;
}

._1kAHd {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content:center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  margin: auto;
  top: calc(50% - 60px);
  left: 0;
  font-size: 18px;
  font-weight: 500;
  z-index: 2;
  color: black;
  pointer-events: none;
}

._gUKoy {
  top: calc(50% - 40px);
}

._1kAHd._3sSDw {
  margin-top: 15px;
  top: calc(50% - 0px);
  font-size: 16px;
  display: grid;
  align-items: center;
  justify-items: center;
}

._2kMca {
  padding: 0;
  margin: 0;
  height: 200px;
  display: block;
}

._1glhn {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

._2ZrAb {
  height: 25%;
  width: 25%;
  background: #eee;
  border: 1px solid #ddd;
  padding-left: 5px;
}

._2QhrQ {
  width: 55px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 20%;
  font-size: 22px;
  border: 3px solid lightgrey;
}

._1YmQx {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
}

._1Kg2- {
  display: block !important;
}

._1WMyQ {
  font-weight: bold;
}

._3O_WH {
  padding-left: 5px;
  font-weight: bold;
  text-transform: capitalize;
}

._3jwoj {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

path {
  stroke-linejoin: round;
}

/******************* Wrapper styles **************/
._3kVsq  {
  height: 192px;
  border-radius: 16px;
  box-shadow: black 0px 0px 20px -8px;
  border: none;
}

._3CO3Z {
  left: 7px;
  right: 7px;
  position: absolute;
}

._1lkOB {
  padding: 2%;
  font-size: 20;
  display: inline-flex;
}

.ant-input-number-handler-wrap {
  width: 0px!important;
  display: none;
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
  margin-right: -4px;
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  margin-right: -4px;
}

.ant-input-number-lg input {
  height: 44px;
}

@media (min-width: 900px) {
  ._1kAHd {
    top: calc(50% - 50px);
  }

  ._2QhrQ {
    width: 40px;
    height: 36px;
    line-height: 36px;
    font-size: 18px;
  }

  .ant-input-number-input {
    padding: 0 7px !important;
  }

  .ant-input-number-lg input {
    height: 36px;
  }

}

._3sNiW {
  width: auto;
}
._3SZHP {
  background: white;
  height: 100%;
  width: 100%;
}
._1PHn4 {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 40px;
}
._GTQ1N {
  text-transform: capitalize;
}
._mdZF1 {
  font-size: 22px;
}
._1_3fM {
  padding: 4px 0px !important;
}
._1eAT_ {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
._1S5eO {
  padding-top: 5px;
  color: red;
  font-size: 12px;
}
/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  #_2I6aY {
    width: 12px;
    height: 12px;
    margin-left: 32px;
  }
  #_2L-_L {
    width: 15px;
    height: 15px;
    margin-left: -700px;
  }
}
/* Landscape */
/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  #_2I6aY {
    width: 14px;
    height: 14px;
    margin-left: 36px;
  }
  #_2L-_L {
    width: 18px;
    height: 18px;
    margin-left: -650px;
  }
}
/* Landscape */
/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Portrait and Landscape */
/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  #_2I6aY {
    width: 14px;
    height: 14px;
    margin-left: 36px;
  }
  #_2L-_L {
    width: 18px;
    height: 18px;
    margin-left: -600px;
  }
}
/* Landscape */
/* ----------- iPhone X ----------- */
/* Portrait and Landscape */
/* Portrait */

._1Ma7E {
  display: flex;
}
._1IjKl {
  flex: 1;
}
._30e5v {
  flex: 0;
}
._2UUpF ._2pZwL {
  margin-right: 8px !important;
}
._17gxE {
  display: flex;
  justify-content: space-between;
}
._2o2WZ {
  color: red;
  font-size: 14px;
  margin-top: 20px;
  display: block;
  text-align: center;
}

.settings-menu li:hover {
  color: unset;
}
.settings-menu li > button {
  margin-left: 10px;
}

@import "~leaflet/dist/leaflet.css";
.map-wrapper {
  display: flex;
  height: 100%;
}
.map-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
.map-leaflet {
  width: 100%;
  height: 100%;
  overflow: hidden;
  visibility: inherit;
}
.leaflet-pane {
  z-index: 0;
}
.leaflet-edit-marker-selected {
  background-color: rgba(255, 255, 255, 0);
  border: 0px dashed rgba(255, 255, 255, 0);
}
.leaflet-tooltip-waterpressure {
  position: absolute;
  padding: 1px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgb(0 0 0%);
}
.label-marker-text {
  color: white;
  font-weight: bold;
  font-size: 24p;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}
.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}
.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}
.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}
.leaflet-oldie .marker-cluster-small {
  background-color: #b5e28c;
}
.leaflet-oldie .marker-cluster-small div {
  background-color: #6ecc39;
}
.leaflet-oldie .marker-cluster-medium {
  background-color: #f1d357;
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: #f0c20c;
}
.leaflet-oldie .marker-cluster-large {
  background-color: #fd9c73;
}
.leaflet-oldie .marker-cluster-large div {
  background-color: #f18017;
}
.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}

.vector-marker {
  width: 35px;
  height: 46px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  text-align: center;
}
.vector-marker path {
  stroke: black;
  stroke-opacity: 0.4;
  stroke-width: 1;
}
.vector-marker.not-accepted {
  opacity: 0.5;
}
.vector-marker-shadow:before {
  bottom: -13px;
  box-shadow: 35px -10px 10px rgba(0, 0, 0, 0.4);
  content: "";
  height: 40%;
  position: absolute;
  right: 21px;
  transform-origin: 50% 50%;
  transform: skew(150deg) rotate(-40deg);
  width: 60%;
  z-index: -1;
}
.vector-marker .icon-white {
  color: #fff;
}
.vector-marker {
  width: 36px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  text-align: center;
}
.vector-marker path.pin-outer {
  stroke: black;
  stroke-opacity: 0.4;
  stroke-width: 1;
  fill-opacity: 0.5;
}
.vector-marker path.pin-inner {
  stroke: white;
  stroke-opacity: 0.1;
  stroke-width: 2;
}
.vector-marker.not-accepted {
  opacity: 0.5;
}
.vector-marker-shadow {
  background: url("/images/shadow.png") no-repeat 0 0;
  width: 36px;
  height: 16px;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vector-marker {
    background-size: 720px 46px;
  }
  .vector-marker-shadow {
    background-image: url("/images/shadow@2x.png");
    background-size: 54px 51px;
  }
}
.vector-marker i {
  position: absolute;
  top: 7px;
  left: 0;
  width: 34px;
  color: #333;
  margin: auto;
  display: inline-block;
  font-size: 18px;
}
.vector-marker .icon-white {
  color: #fff;
}
.vector-marker:hover path.pin-outer {
  stroke: white;
  stroke-width: 2;
  stroke-opacity: 1;
}
.selected-icon path.pin-outer {
  stroke: white;
  stroke-width: 2;
  stroke-opacity: 1;
}
.leaflet-edit-marker-selected {
  border-color: rgba(255, 255, 255, 0);
}
.leaflet-edit-marker-selected path.pin-outer {
  stroke: white;
  stroke-width: 2;
  stroke-opacity: 1;
}
.marker-is-selected {
  z-index: 3333 !important;
  border-color: rgba(255, 255, 255, 0);
}
.marker-is-selected path.pin-outer {
  stroke: white;
  stroke-width: 2;
  stroke-opacity: 1;
}
.marker-not-selected {
  border-color: rgba(255, 255, 255, 0);
  opacity: 0.2;
}
.marker-not-selected path.pin-outer {
  stroke: white;
  stroke-width: 1;
  stroke-opacity: 0.2;
}
svg.marker-svg-not-in-block path.pin-outer {
  stroke: red !important;
  stroke-width: 2;
  stroke-opacity: 1;
}
.marker-cluster div {
  font-size: 16px;
  font-weight: bold;
  width: 34px;
  height: 34px;
  margin-left: 3px;
  margin-top: 3px;
  line-height: 34px;
}
.marker-cluster {
  color: white;
}
.marker-cluster-offline {
  background-color: rgba(204, 204, 204, 0.6);
  color: gray;
}
.marker-cluster-offline div {
  background-color: #ccc;
}
.marker-cluster-unknown {
  background-color: rgba(255, 255, 255, 0.6);
  color: gray;
}
.marker-cluster-unknown div {
  background-color: white;
}
.marker-cluster-critical {
  background-color: rgba(226, 53, 73, 0.6);
}
.marker-cluster-critical div {
  background-color: #f5222d;
}
.marker-cluster-bad {
  background-color: rgba(226, 53, 73, 0.6);
}
.marker-cluster-bad div {
  background-color: #fa8c16;
}
.marker-cluster-warning {
  background-color: rgba(255, 204, 0, 0.6);
}
.marker-cluster-warning div {
  background-color: #fadb14;
}
.marker-cluster-over {
  background-color: rgba(66, 154, 255, 0.6);
}
.marker-cluster-over div {
  background-color: #1890FF;
}
.marker-cluster-good {
  background-color: rgba(61, 200, 115, 0.6);
}
.marker-cluster-good div {
  background-color: green;
}
.marker-cluster:hover div {
  margin-top: 1px;
  margin-left: 1px;
}
.marker-cluster:hover {
  border: 2px solid white;
  color: black;
}

/* .ant-select-tree-title  */
._3Aaht {
  background: #333 !important;
  color: white;
  height: 20px;
  line-height: 20px;
}
._1WYA7 {
  background: #e6e6e6;
  font-weight: bold;
  font-size: 20px;
}
._1WYA7,
._3Gc7e,
._1n7BN,
._2q6e5 {
  line-height: 40px;
  height: 40px;
}
._1WYA7,
._3Gc7e,
._1n7BN,
._3Aaht,
._2q6e5 {
  width: 100%;
  overflow-wrap: break-word;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._2q6e5 {
  font-weight: bold;
  font-size: 20px;
}
._3Gc7e,
._1n7BN {
  padding-left: 15px !important;
  font-size: 20px;
}
._3Gc7e:nth-child(odd) {
  background: #e6e6e6;
}
body {
  overflow: hidden;
}
._3e__W,
._3Aaht,
._1WYA7,
._2q6e5 {
  padding-left: 5px !important;
}
._3TDid,
._3uXsn {
  display: flex;
  align-items: center;
  justify-content: center;
}
._3-3ZQ {
  background: #3cb7f3 !important;
}
.ant-select-tree .ant-select-tree-switcher {
  width: 0px !important;
  height: 0px !important;
  font-size: 20px !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  margin-left: -4px;
}
.ant-select-tree-list-scrollbar {
  width: 4px !important;
}
.ant-select-tree-indent-unit {
  width: 0px !important;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 3px !important;
}
@media (min-width: 320px) and (max-width: 750px) {
  .ant-tree-select-dropdown {
    width: 100% !important;
    left: 0px !important;
    top: 0px !important;
  }
}

._1vsgf {
    height: 50px;
    width: 50px;
    border-radius: 20px;
    cursor: pointer;
}
  