.adm-tab-bar-wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  min-height: 50px;
}
.adm-tab-bar-item {
  flex: 1;
  color: var(--adm-color-weak);
  white-space: nowrap;
  padding: 4px 8px;
  width: min-content;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.adm-tab-bar-item-icon {
  font-size: 20px;
  height: 22px;
  line-height: 1;
  margin: 4px 0 1px;
}
.adm-tab-bar-item-title {
  font-size: 11px;
  line-height: 15px;
}
.adm-tab-bar-item-active {
  color: var(--adm-color-primary);
}
.adm-tab-bar-icon-badge {
  --top: 6px;
}
.adm-tab-bar-title-badge {
  --right: -2px;
  --top: -2px;
}
