.adm-safe-area {
  display: block;
  width: 100%;
}
.adm-safe-area-position-top {
  padding-top: env(safe-area-inset-top);
}
.adm-safe-area-position-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}
